import React from "react";
import {
    FaArrowUp,
  } from 'react-icons/fa';
import { Link } from 'react-scroll';
import { useState, useEffect } from 'react';
import Aos from 'aos';
import "aos/dist/aos.css";



const About = () => { 
  const [showTopBtn, setShowTopBtn] = useState(false);

useEffect(() => {
  window.addEventListener('scroll', () => {
      if (window.scrollY > 750) {
          setShowTopBtn(true);
      } else {
          setShowTopBtn(false);
      }
  });
}, []);
useEffect (()=>{
  Aos.init({duration: 1500, useClassNames: false});
}, [])
  return (
    <div
      name="about"
      id="about"
      className="w-full h-screen bg-[#0a192f] text-gray-300"
      
    >
      <div className="flex flex-col justify-center items-center w-full h-full"data-aos="fade-up">
        <div className=" py-8 rounded-md bg-red-700 flex flex-col justify-center items-center w-4/6">
          <div className="max-w-[1000px] w-full grid grid-cols-2 gap-8 mb-4" >
            <div className="sm:text-right pb-8 pl-4" data-aos="fade-left">
              <p className="text-4xl font-bold inline border-b-4 border-[#0a192f]">
                About
              </p>
            </div>
            <div></div>
          </div>
          <div className="max-w-[1000px] w-full grid sm:grid-cols-2 gap-8 px-4">
            <div className="sm:text-left text-4xl font-bold" data-aos="fade-right">
              <p>
                Hi. I'm M0nke, nice to meet you. Please take a
                look around.
              </p>
            </div>
            <div data-aos="fade-right" data-aos-offset="60">
              <p>
                {" "}
                A web developer with experience in building Responsive and
                Scalable Websites. I am well-knowledged in UI/UX principles and
                practices. In addition to web development, I am also a
                Bot developer, Server builder & Admin/Mod on Discord.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className='lg:flex fixed flex-col top-[90%] right-0 z-20'>
        <ul>
          <li className='w-[60px] h-[60px] flex justify-between items-center ml-[-80px]'>
            {showTopBtn && <Link
              className='flex justify-between items-center w-full text-gray-300'
              to='home' smooth={true} duration={500}
              data-aos="fadeIn"
            >
              <FaArrowUp size={30} color='red' />
            </Link>}
          </li>
        </ul>
        
      </div>
    </div>
  );
};
export default About;