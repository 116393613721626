import React, { useEffect } from 'react'; 
import Aos from 'aos';
import "aos/dist/aos.css";
import {
  FaDiscord,
  FaGithub,
  FaTwitterSquare,
} from 'react-icons/fa';

const Footer = () => {
  useEffect (()=>{
    Aos.init({duration: 2000, useClassNames: false});
  }, [])
  return (
    <div className='w-full h-[150px] flex items-center px-4 bg-[#0a192f] text-red-500 z-40'>
      <div className='w-full text-center flex-row py-8 md:flex-row md:justify-center md:items-center' data-aos="fadeIn">
        <div className='py-4'>
          <p>Copyright © M0nke. All rights are reserved.</p>
        </div>
        <div className='flex justify-center items-center'>
          <a
            className='justify-center px-4 text-blue-600'
            href='https://twitter.com/itsM0nke'
            target='_blank'
            rel='noreferrer'
          >
            <FaTwitterSquare size={30} />
          </a>
          <a
            className='justify-center px-4 text-gray-300'
            href='https://github.com/dia8-8'
            target='_blank'
            rel='noreferrer'
          >
            <FaGithub size={30} />
          </a>
          <a
            className='justify-center pl-4 text-gray-300'
            href='https://discord.gg/PAKWYbTzut'
            target='_blank'
            rel='noreferrer'
          >
            <FaDiscord size={30} />
          </a>
          <p
            className='justify-center pl-2 text-gray-300 display: inline'
          >
            _m0nke
          </p>
        </div>
      </div>
    </div>
  );
};
export default Footer;