import React , { useState, useEffect } from 'react';
import axios from 'axios';
import Aos from 'aos';
import "aos/dist/aos.css";


const Contact = () => {
  const [status, setStatus] = useState({
    submitted: false,
    submitting: false,
    info: { error: false, msg: null },
  });
  const [inputs, setInputs] = useState({
    name: '',
    email: '',
    message: '',
  });
  const handleServerResponse = (ok, msg) => {
    if (ok) {
      setStatus({
        submitted: true,
        submitting: false,
        info: { error: false, msg: msg },
      });
      setInputs({
        name:'',
        email: '',
        message: '',
      });
    } else {
      setStatus({
        info: { error: true, msg: msg },
      });
    }
  };
  const handleOnChange = (e) => {
    e.persist();
    setInputs((prev) => ({
      ...prev,
      [e.target.id]: e.target.value,
    }));
    setStatus({
      submitted: false,
      submitting: false,
      info: { error: false, msg: null },
    });
  };
  const handleOnSubmit = (e) => {
    e.preventDefault();
    setStatus((prevStatus) => ({ ...prevStatus, submitting: true }));
    axios({
      method: 'POST',
      url: 'https://formspree.io/mjvdrpkg',
      data: inputs,
    })
      .then((response) => {
        handleServerResponse(
          true,
          'Thank you, your message has been submitted.',
        );
      })
      .catch((error) => {
        handleServerResponse(false, error.response.data.error);
      });
  };
  useEffect(()=>{
    Aos.init({duration: 2000, useClassNames: false});
  }, [])
    return (
      <div name='contact' className='w-full h-screen bg-[#0a192f] flex justify-center items-center p-4'>
        <form className='flex flex-col max-w-[600px] w-full' onSubmit={handleOnSubmit}>
              <div className='pb-8 flex flex-col justify-center w-full h-full items-center' data-aos="fade-right">
                  <p className='text-4xl font-bold inline border-b-4 border-red-600 text-gray-300'>Contact</p>
                  <p className='text-gray-300 py-4'>Send me a message</p>
              </div>
              <div className='pb-8 flex flex-col justify-center w-full h-full items-center' data-aos="fade-up">
                <input  className='mb-4 p-2 w-full bg-[#ccd6f6] border-b-4 border-red-500 rounded-t-lg' placeholder='Name' id="name" type="text" name="name" onChange={handleOnChange} required value={inputs.name}/>
                <input  className='mb-4 p-2 w-full bg-[#ccd6f6] border-b-4 border-red-500 rounded-t-lg' placeholder='Email' id="email" type="email" name="_replyto" onChange={handleOnChange} required value={inputs.email}/>
                <textarea  className='bg-[#ccd6f6] w-full p-2 border-b-4 border-red-500 rounded-t-lg' placeholder='Message' rows="10" id="message" name="message" onChange={handleOnChange} required value={inputs.message}></textarea>
                <button  className='text-gray-300 border-2 border-red-500 rounded-md hover:bg-red-600 hover:border-red-500 px-4 py-3 my-8 mx-auto flex items-center' disabled={status.submitting} type="submit">{!status.submitting
              ? !status.submitted
                ? 'Submit'
                : 'Submitted'
              : 'Submitting...'}</button>
              {status.info.msg && (<p className={`text-red-500`}>{status.info.msg}</p>)}
              </div>
        </form>
      </div>
    )
  }
  export default Contact