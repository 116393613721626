import About from "./components/About";
import Contact from "./components/Contact";
import Home from "./components/Home";
import Navbar from "./components/Navbar";
import Skills from "./components/Skills";
import Works from "./components/Works";
import Discord from "./components/Discord"
import Footer from './components/Footer';
import { inject } from '@vercel/analytics';

function App() { 
  inject();
  return (
    <div>
      <Navbar />
      <Home />
      <About />
      <Skills />
      <Discord />
      <Works />
      <Contact />
      <Footer />
    </div>
  );
}
export default App;
