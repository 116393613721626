import React, { useState, useEffect } from 'react'; 
import {
  FaBars,
  FaDiscord,
  FaTimes,
  FaTwitterSquare,
  FaGithub,
} from 'react-icons/fa';
import { Link } from 'react-scroll';
import Aos from 'aos';
import "aos/dist/aos.css";

const Navbar = () => {
  const [nav, setNav] = useState(false);
  const handleClick = () => setNav(!nav);
  useEffect (()=>{
    Aos.init({duration: 2000, useClassNames: false});
  }, [])
  return (
    <div name="nav" className='fixed w-full h-[60px] flex justify-between items-center top-0 px-5 bg-[#0a192f] text-gray-300 z-10'>
        <ul>
          <li className=' text-4xl sm:text-3xl font-bold text-red-600' data-aos="fadeIn" data-aos-duration='3000'>
            <Link to='home' smooth={true} duration={500}>
              M0nke
            </Link>
          </li>
        </ul>
      {/* menu */}
      <ul className='hidden md:flex gap-x-8' data-aos="fadeIn">
        <li className='hover:text-red-500 '>
          <Link to='home' smooth={true} duration={500} >
            Home
          </Link>
        </li>
        <li className='hover:text-red-500 '>
          <Link to='about' smooth={true} duration={500}>
            About
          </Link>
        </li>
        <li className='hover:text-red-500 '>
          <Link to='skills' smooth={true} duration={500}>
            Skills
          </Link>
        </li>
        <li className='hover:text-red-500 '>
          <Link to='work' smooth={true} duration={500}>
            Experience
          </Link>
        </li>
        <li className='hover:text-red-500 '>
          <Link to='contact' smooth={true} duration={500}>
            Contact
          </Link>
        </li>
      </ul>
      {/* Hamburger */}
      <div onClick={handleClick} className='md:hidden z-20'>
        {!nav ? <FaBars /> : <FaTimes />}
      </div>
      {/* Mobile menu */}
      <ul
        className={
          !nav
            ? 'hidden'
            : 'absolute top-0 left-0 w-full h-screen bg-[#0a192f] flex flex-col justify-center items-center z-10'
        }
      >
        <li className='py-6 text-4xl'>
          <Link onClick={handleClick} to='home' smooth={true} duration={500}>
            Home
          </Link>
        </li>
        <li className='py-6 text-4xl'>
          {' '}
          <Link onClick={handleClick} to='about' smooth={true} duration={500}>
            About
          </Link>
        </li>
        <li className='py-6 text-4xl'>
          {' '}
          <Link onClick={handleClick} to='skills' smooth={true} duration={500}>
            Skills
          </Link>
        </li>
        <li className='py-6 text-4xl'>
          {' '}
          <Link onClick={handleClick} to='work' smooth={true} duration={500}>
            Experience
          </Link>
        </li>
        <li className='py-6 text-4xl'>
          {' '}
          <Link onClick={handleClick} to='contact' smooth={true} duration={500}>
            Contact
          </Link>
        </li>
      </ul>
      {/* Social icons */}
      <div data-aos="fade-right" className='hidden lg:flex fixed flex-col top-[45%] left-0'>
        <ul>
          <li className='w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-blue-600'>
            <a
              className='flex justify-between items-center w-full text-gray-300'
              href='https://twitter.com/itsM0nke'
              target='_blank'
              rel='noreferrer'
            >
              Twitter <FaTwitterSquare size={30} />
            </a>
          </li>
          <li className='w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-gray-600'>
            <a
              className='flex justify-between items-center w-full text-gray-300'
              href='https://discord.gg/PAKWYbTzut'
              target='_blank'
              rel='noreferrer'
            >
              My Discord <FaDiscord size={30} />
            </a>
          </li>
          <li className='w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-[#232427]'>
            <a
              className='flex justify-between items-center w-full text-gray-300'
              href='https://github.com/dia8-8'
              target='_blank'
              rel='noreferrer'
            >
              Github <FaGithub size={30} />
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};
export default Navbar;