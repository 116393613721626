import React, {useEffect} from 'react';
import WidgetBot from '@widgetbot/react-embed'
import Aos from 'aos';
import "aos/dist/aos.css";

const Discord = () => {
  useEffect(()=>{
    Aos.init({duration: 2000, useClassNames: false});
  }, [])
    return (
      <div className='w-full h-full bg-[#0a192f] flex justify-center items-center p-4'>
        <div className='max-w-[1200px] mx-auto p-4 flex justify-center w-full h-full' data-aos="zoom-in">
            <WidgetBot
                server="1079579432848728154"
                channel="1079952309863329832"
                height="700"
                width="1200"
            />
        </div>
      </div>
    )
  }
  export default Discord