import React, {useEffect} from 'react';
import hh from '../assets/hh.png';
import mv from '../assets/mv.png';
import dad from '../assets/dad.png';
import mn from '../assets/mn.png';
import morti from '../assets/morti.png';
import v from '../assets/v.png';
import nakama from '../assets/nakama.png';
import ind3x from '../assets/ind3xICON.png';
import ehive from '../assets/Ehive.png';
import Aos from 'aos';
import "aos/dist/aos.css";

const Works = () => {
  useEffect (()=>{
    Aos.init({duration: 2000, useClassNames: false});
  }, [])
  return (
    
    <div name='work' className='w-full md:h-screen text-gray-300 bg-[#0a192f] py-16'>
      <div className='max-w-[1000px] mx-auto p-4 flex flex-col justify-center w-full h-full'>
            
        <div className='pb-8 w-full flex justify-center items-center flex-col' data-aos="fade-right" >
          <p className='text-4xl font-bold inline border-b-4 text-gray-300 border-red-600'>
            Experience
          </p>
          <p className='py-6 text-2xl'>Check out some of my previous experience</p>
        </div>
        {/* Container */}
        <div className='grid sm:grid-cols-2 md:grid-cols-3 gap-4' data-aos="zoom-in">
            {/* Grid Item */}
          <div
            style={{ backgroundImage: `url(${hh})` }}
            className='shadow-lg shadow-[#040c16] group container rounded-md flex justify-center items-center mx-auto content-div'
          >
            {/* Hover Effects */}
            <div className=" opacity-0 group-hover:opacity-100 flex justify-center items-center flex-col ease-in duration-300" >
              <span className=' text-lg font-bold text-white tracking-wider'>
              @HappyHomiesNFT
              </span>
              <p className='text-center'>Language Mod (Feb 2022 - April 2022)</p>
              <div className='pt-8 text-center'>
                <a href='https://twitter.com/HappyHomiesNFT' target="blank">
                  <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-red-500 font-bold text-lg'>
                    Twitter
                  </button>
                </a>
                <a href='https://opensea.io/collection/happyhomiesnft' target="blank">
                  <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-red-500 font-bold text-lg'>
                    OpenSea
                  </button>
                </a>
              </div>
            </div>
          </div>
          <div
            style={{ backgroundImage: `url(${mv})` }}
            className='shadow-lg shadow-[#040c16] group container rounded-md flex justify-center items-center mx-auto content-div'
          >
            {/* Hover Effects */}
            <div className='opacity-0 group-hover:opacity-100 flex justify-center items-center flex-col ease-in duration-300'>
              <span className=' text-lg font-bold text-white tracking-wider'>
              @_MiniverseNFT
              </span>
              <p className='text-center'>Senior Moderator (May 2022- Sep 2022)</p>
              <div className='pt-8 text-center'>
                <a href='https://twitter.com/_MiniverseNFT' target="blank">
                  <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-red-500 font-bold text-lg'>
                    Twitter
                  </button>
                </a>
                <a href='https://opensea.io/collection/miniverse-genesis' target="blank">
                  <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-red-500 font-bold text-lg'>
                    OpenSea
                  </button>
                </a>
              </div>
            </div>
          </div>
          <div
            style={{ backgroundImage: `url(${dad})` }}
            className='shadow-lg shadow-[#040c16] group container rounded-md flex justify-center items-center mx-auto content-div'
          >
            {/* Hover Effects */}
            <div className='opacity-0 group-hover:opacity-100 flex justify-center items-center flex-col ease-in duration-300'>
              <span className=' text-lg font-bold text-white tracking-wider'>
              @DadnessNFT

              </span>
              <p className='text-center'>Mod/Helper (May 2022 - July 2022)</p>
              <div className='pt-8 text-center'>
                <a href='https://twitter.com/DadnessNFT' target="blank">
                  <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-red-500 font-bold text-lg'>
                    Twitter
                  </button>
                </a>
                <a href='https://opensea.io/collection/dads-by-rami-niemi' target="blank">
                  <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-red-500 font-bold text-lg'>
                    OpenSean
                  </button>
                </a>
              </div>
            </div>
          </div>
          <div
            style={{ backgroundImage: `url(${mn})` }}
            className='shadow-lg shadow-[#040c16] group container rounded-md flex justify-center items-center mx-auto content-div'
          >
            {/* Hover Effects */}
            <div className='opacity-0 group-hover:opacity-100 flex justify-center items-center flex-col ease-in duration-300'>
              <span className=' text-lg font-bold text-white tracking-wider'>
              @MonsterNumberz
              </span>
              <p className='text-center'>Collab Manager/Mod (Jun 2022 - Nov 2022)</p>
              <div className='pt-8 text-center'>
                <a href='https://twitter.com/MonsterNumberz' target="blank">
                  <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-red-500 font-bold text-lg'>
                    Twitter
                  </button>
                </a>
                <a href='https://opensea.io/collection/monster-numberz' target="blank">
                  <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-red-500 font-bold text-lg'>
                    OpenSea
                  </button>
                </a>
              </div>
            </div>
          </div>
          <div
            style={{ backgroundImage: `url(${morti})` }}
            className='shadow-lg shadow-[#040c16] group container rounded-md flex justify-center items-center mx-auto content-div'
          >
            {/* Hover Effects */}
            <div className='opacity-0 group-hover:opacity-100 flex justify-center items-center flex-col ease-in duration-300'>
              <span className=' text-lg font-bold text-white tracking-wider'>
              @Mortiverse

              </span>
              <p className='text-center'>Community Mod (Jul 2022 - Oct 2022)</p>
              <div className='pt-8 text-center'>
                <a href='https://twitter.com/Mortiverse' target="blank">
                  <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-red-500 font-bold text-lg'>
                    Twitter
                  </button>
                </a>
                <a href='https://opensea.io/collection/mortiverse-s1' target="blank">
                  <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-red-500 font-bold text-lg'>
                    OpenSea
                  </button>
                </a>
              </div>
            </div>
          </div>
          <div
            style={{ backgroundImage: `url(${v})` }}
            className='shadow-lg shadow-[#040c16] group container rounded-md flex justify-center items-center mx-auto content-div'
          >
            {/* Hover Effects */}
            <div className='opacity-0 group-hover:opacity-100 flex justify-center items-center flex-col ease-in duration-300'>
              <span className=' text-lg font-bold text-white tracking-wider'>
              @voiders_world

              </span>
              <p className='text-center'>Moderator (Oct 2022 - Jan 2023)</p>
              <div className='pt-8 text-center'>
                <a href='https://twitter.com/voiders_world' target="blank">
                  <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-red-500 font-bold text-lg'>
                    Twitter
                  </button>
                </a>
                <a href='https://opensea.io/collection/voidersgen-official' target="blank">
                  <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-red-500 font-bold text-lg'>
                    OpenSea
                  </button>
                </a>
              </div>
            </div>
          </div>
          <div
            style={{ backgroundImage: `url(${nakama})` }}
            className='shadow-lg shadow-[#040c16] group container rounded-md flex justify-center items-center mx-auto content-div'
          >
            {/* Hover Effects */}
            <div className='opacity-0 group-hover:opacity-100 flex justify-center items-center flex-col ease-in duration-300'>
              <span className=' text-lg font-bold text-white tracking-wider'>
              @Nakama_MC

              </span>
              <p className='text-center'>Discord Manager & Bot Developer (Mar 2023 - July 2023)</p>
              <div className='pt-8 text-center'>
                <a href='https://twitter.com/Nakama_MC' target="blank">
                  <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-red-500 font-bold text-lg'>
                    Twitter
                  </button>
                </a>
                <a href='https://opensea.io/' target="blank">
                  <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-red-500 font-bold text-lg'>
                    OpenSea
                  </button>
                </a>
              </div>
            </div>
          </div>
          <div
            style={{ backgroundImage: `url(${ind3x})` }}
            className='shadow-lg shadow-[#040c16] group container rounded-md flex justify-center items-center mx-auto content-div'
          >
            {/* Hover Effects */}
            <div className='opacity-0 group-hover:opacity-100 flex justify-center items-center flex-col ease-in duration-300'>
              <span className=' text-lg font-bold text-white tracking-wider'>
              @IND3Xapp

              </span>
              <p className='text-center'>Discord Manager (Sep 2023 - Present)</p>
              <div className='pt-8 text-center'>
                <a href='https://twitter.com/IND3Xapp' target="blank">
                  <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-red-500 font-bold text-lg'>
                    Twitter
                  </button>
                </a>
                <a href='https://v2.ind3x.app/' target="blank">
                  <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-red-500 font-bold text-lg'>
                    Website
                  </button>
                </a>
              </div>
            </div>
          </div>
          <div
            style={{ backgroundImage: `url(${ehive})` }}
            className='shadow-lg shadow-[#040c16] group container rounded-md flex justify-center items-center mx-auto content-div'
          >
            {/* Hover Effects */}
            <div className='opacity-0 group-hover:opacity-100 flex justify-center items-center flex-col ease-in duration-300'>
              <span className=' text-lg font-bold text-white tracking-wider'>
              @EHivexyz

              </span>
              <p className='text-center'>Discord Manager (Nov 2023 - Present)</p>
              <div className='pt-8 text-center'>
                <a href='https://twitter.com/EHivexyz' target="blank">
                  <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-red-500 font-bold text-lg'>
                    Twitter
                  </button>
                </a>
              </div>
            </div>
          </div>
          
        </div>
      </div>
    </div>
  );
};
export default Works;